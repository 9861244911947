import React from 'react';
import PandoraModel from "traits/PandoraModel";
import IcecreamVanIcon from "assets/icons/IcecreamVan.js";
import ColorLensIcon from '@material-ui/icons/ColorLens';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import * as License from "license.js";
import * as Permissions from "permission.js";
import VanButton from 'components/VanButton/VanButton';
import LocaleFromNow from 'components/LocaleFromNow/LocaleFromNow';

class IcecreamVan extends PandoraModel {
	static modelName = "IcecreamVan";
	static getLicense() { return License.ICETRUCK}

	static icon = IcecreamVanIcon;

	// static orderable = true;
	// static copyable = true;
	// static loadBeforeCopy = false;
	// static hasCrudPages = false;
	// static crudAsNotification = true;
	// static editableOnTable = true;

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE

	static cards = [
		{
			title: "GENERAL_INFO",
			icon: IcecreamVanIcon,
			fields: {
				// PANDORA_ANCHOR_INSERT_FIELDS_HERE
				nr: { type: "number", step: "1", required: true, showOnTable: true, md: 4, },
				name: { type: "text", maxLength: "255", required: true, showOnTable: true, md: 4, valueOnTable: (resource) => <VanButton name={resource.name} color_background={resource.color_background} color_text={resource.color_text} />, },
				license_plate: { type: "text", maxLength: "255", required: false, md: 4, showOnTable: true, },
				subtitle: { type: "text", maxLength: "255", required: false, },
				internal_note: { type: "text", maxLength: "255", required: false, },
				damage_report_background_image_uri: { type: "file", filetypes: [".png", ".jpg", ".jpeg"], required: false, },

				latitude: { type: "hidden", },
				longitude: { type: "hidden", },
				velocity: { type: "hidden", },

				updated_at: { type: "datetime", required: false, visible: false, showOnTable: true, valueOnTable: (resource) => <LocaleFromNow value={resource.updated_at} />, },
			},
		},
		{
			title: "COLORS",
			icon: ColorLensIcon,
			fields: {
				color_text: { type: "color", palette: "text", required: true, md: 6, },
				color_background: { type: "color", palette: "background", required: true, md: 6, },
			},
		},
		{
			title: "ONBOARD_DOCUMENTS",
			icon: AttachFileIcon,
			fields: {
				registration_certificate_uri: { type: "file", filetypes: [".png", ".jpg", ".jpeg", ".pdf"], required: false, md: 6,},
				empty_after_registration_certifcate_uri : { type: "empty", md: 6,},
				insurance_uri: { type: "file", filetypes: [".png", ".jpg", ".jpeg", ".pdf"], required: false, md: 6, },
				insurance_expires_at: { type: "datetime", md:6,},
				inspection_certificate_uri: { type: "file", filetypes: [".png", ".jpg", ".jpeg", ".pdf"], required: false, md: 6,},
				inspection_expires_at: { type: "datetime", md:6,},

			},
		},
		
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: FiberNewIcon,
		// 	fields: {
		// 		// text: {type: "text", required: true, },
		// 		// textarea: {type: "text", multiline: true, rows: 12, required: true, },
		// 		// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}, },
		// 		// select: {type: "select", required: true, options: ["OPTION"], },
		// 		// checkbox: {type: "checkbox", },
				
		// 		// md12: {type: "text", md: 12, },
		// 		// showOnTable: {type: "text", showOnTable: true, },
		// 	},
		// },

	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
	// static getCopyableChildren() { return [IcecreamVanItem]; };
}

export default IcecreamVan;